// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
const myCustomTheme = {
  dark: false,
  colors: {
    greyColorVarient1: "#f3f3f3",
    greyColorVarient2: "#d9d9d9",
    greyColorVarient3: "#9e9e9e",
    greyColorVarient4: "#808080",
    greyColorVarient5: "#606060",

    greenColorVariant1: "#cefcce",

    blueColorVariant1: "#003A9F",
    blueColorVarient2: "#ccd7eb",

    redColorVariant1: "#FFE5E5",

    whiteColorVariant: "#fff"
  },
};
export default createVuetify(
  {
    theme: {
      defaultTheme: "myCustomTheme",
      themes: {
        myCustomTheme,
      },
    },
  }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
);


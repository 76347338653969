<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-app-bar flat  density="compact" color="#f5f5f5" class="px-5">
      <div class="fontStyleVariant4">bank<span class="fontStyleVariant5">Xchange</span></div>
      <v-spacer />
      <div>{{ $store.getters.get_user_email }}</div>
      <v-btn icon color="blueColorVariant1" @click="$router.push('/')"><v-icon>mdi-logout</v-icon></v-btn>
    </v-app-bar>
    <v-navigation-drawer  fixed permanent color="blueColorVariant1" width="240">
      <v-card-text>
        <v-card class="elevation-0" color="transparent"> </v-card>
        <v-card class="elevation-0" color="transparent" active-class="white" v-for="(item, idx) in RouterList" :key="idx" @click="checkItem(item)">
          <v-card-actions class="cursorPointer pa-0 ma-0 CustomizedCardActions mt-2" :class="$route.name == item.MenuRoute ? 'white--text' : 'white--text'">
            <div class="">
              <span
                ><v-icon color="white" small class="mr-2">{{ item.MenuIcon }}</v-icon></span
              >
              {{ item.MenuName }}
            </div>
            <v-spacer> </v-spacer>
            <v-icon v-if="item.SubMenu">mdi-chevron-down</v-icon>
          </v-card-actions>
          <v-card class="pl-4 transparent elevation-0" v-show="item.SubMenu" v-for="(sitem, sidx) in item.SubMenu" :key="sidx" @click="checkItem(sitem)">
            <v-card-actions class="cursorPointer  pa-0 ma-0" :class="$route.name == sitem.MenuRoute ? 'blueColorVariant1--text' : 'white--text'">
              {{ sitem.MenuName }}
              <v-spacer> </v-spacer>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-card-text>
    </v-navigation-drawer>
    <v-main>
      <router-view :IsTokenGetExpired="IsTokenGetExpired" />
    </v-main>
  </div>
</template>

<script>
import RouterList from "@/JSONFiles/RouterList.json";
// import { GetCurrentUser } from "@/Mixins/GetCurrentUserDetails.js";
import { RefreshToken } from "@/Mixins/CommonGraphqlAPI/RefreshToken.js";
import Overlay from "@/components/Extras/Overlay.vue";
export default {
  components: {
    Overlay,
  },
  mixins: [RefreshToken],
  data: () => ({
    RouterList: [],
    overlay: false,
    IsTokenGetExpired: false,
  }),
  watch: {
    IsTokenGetExpired(val) {
      if (val) {
        this.GetRefreshTokenMethod();
      }
    },
  },
  mounted() {
    // this.GetCurrentUserMethod();
    console.log("check token details", this.$store.getters.get_token_details);
    this.$router.push("/Dashboard");
    this.RouterList = RouterList;
  },
  methods: {
    checkItem(menuRouteObj) {
      if (!menuRouteObj.has_child_routes && menuRouteObj.MenuRoute != this.$route.name) {
        this.$router.push(menuRouteObj.MenuRoute);
      }
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <v-card v-if="overlay" :height="VuetifyObj.height" flat>
      <v-row align="center" justify="center" :style="{ height: `${VuetifyObj.height}px`, color: '#fff' }">
        <v-col cols="12" align="center">
          <v-overlay :model-value="overlay" opacity="0.8"></v-overlay>
          <v-img src="@/assets/loader1.gif" contain max-width="150"></v-img>
          <div class="text-center mt-3">Loading Data...!</div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { useDisplay } from "vuetify/lib/framework.mjs";
export default {
  name: "overlayComp",
  props: {
    overlay: Boolean,
    count: Number,
  },
  data: () => ({
    VuetifyObj: {},
  }),
  mounted() {
    this.VuetifyObj = useDisplay();
  },
};
</script>

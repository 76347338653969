<template>
  <div>
    <v-dialog :model-value="dialogForTermsAndConditions" persistent :max-width="VuetifyObj.width - 20" transition="dialog-top-transition">
      <v-card>
        <v-toolbar density="compact" class="elevation-0" color="">
          <div class="fontSize25px fontFamilyInter px-6 font-weight-bold">Terms & Conditions</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogForTermsAndConditionsEmit(1)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card flat :height="VuetifyObj.height - 50" class="overflow-auto drawer-content">
          <v-card-text class="px-6 pb-0">
            <div class="fontStyleVariant2 pb-5 font-weight-bold">USE OF THIS WEB SITE CONSTITUTES YOUR AGREEMENT TO THE TERMS AND CONDITIONS OF USE ESTABLISHED BY THE MBCA</div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              When you access information provided on the Site, you acknowledge your agreement to these Terms and Conditions. References to "you" or "your" refer to you as an individual as well as in
              any representative capacity you may have.
            </div>
            <div class="fontStyleVariant2 pb-5 font-weight-bold">1. SERVICES PROVIDED</div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              The Site provides information of importance to the business community. The MBCA may, as a service to its members, allow third parties to promote their services and/or products through
              the Site. The provision of such products and services is governed by the terms of use established by such third parties, and the MBCA assumes no responsibility either directly or
              indirectly for the provision of products or services by such third parties.
            </div>
            <div class="fontStyleVariant2 pb-5 font-weight-bold">2. PROPERTY RIGHTS ON THE SITE AND IN USER-SUBMITTED DATA</div>
            <div class="fontStyleVariant2 pb-5 font-weight-bold">2.1 Property Rights on the Site</div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              (a) Subject to these Terms and Conditions the MBCA hereby grants to you, the right to access and use the Site for your own internal use. In your use of the Site you agree not to remove
              any copyright or other notices contained therein. Except as expressly authorized herein, you may not use, copy, reproduce, transmit, translate, offer for sale, sell, display, perform,
              distribute, publish, broadcast, circulate, modify, create derivative works of, disseminate, or commercially exploit the Site without the written consent of the MBCA. You also agree not
              to use the Site for any unlawful purpose.
            </div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              (b) The Site is the property of the MBCA and is protected by applicable copyright, patent, trademark or other intellectual property law. The MBCA retains its title, ownership rights, and
              intellectual property rights in and to the Site. You agree to abide by any copyright notice or other restriction contained in any individual information, software, or other material
              accessible through the Site.
            </div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              (c) In the event you use the Site or the links included on the Site to gain access to a World Wide Web site or Internet location or source of information, services, software, or goods of
              any company, organization, or person other than usMBCA.com, or to any other Internet location, you acknowledge that such other sites or locations are not under the control of the MBCA
              and agree that the MBCA shall not be responsible for any of the information, services, software, or goods or other links found at any such World Wide Web site or Internet location or
              source of information, services, software, or goods, or for your use of such information, services, software, or goods.
            </div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              (d) THE MBCA MAKES NO EXPRESS REPRESENTATION OR WARRANTY WITH RESPECT TO SUCH SITE, DISCLAIMS ALL IMPLIED WARRANTIES (INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT), DISCLAIMS RESPONSIBILITY FOR, AND ASSUMES NO LIABILITY REGARDING, ANY INFORMATION, SERVICES, SOFTWARE, OR
              GOODS AVAILABLE, ADVERTISED, SOLD, OR FOUND ON ANY OTHER WEB SITE NOT UNDER THE MBCA'S CONTROL (INCLUDING, WITHOUT LIMITATION, WEB SITES LINKED TO THE SITE) OR, WITHOUT LIMITING THE
              GENERALITY OF THE FOREGOING, THE QUALITY, SAFETY, ACCURACY, AVAILABILITY, DECENCY, OR SUITABILITY OF SUCH INFORMATION, SERVICES, SOFTWARE, OR GOODS.
            </div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              (e) Transmission or use of any material in violation of these Terms and Conditions - or any applicable law, rule or regulation (whether of the United States or other countries) - or the
              rights of any third party is prohibited. This includes, but is not limited to, copyrighted material, material which is defamatory, threatening, obscene, lewd and indecent, material
              protected by trademark, trade secret, or patent laws, or material that results in an invasion of privacy.
            </div>
            <div class="fontStyleVariant2 pb-5 font-weight-bold">2.2 Property Rights in User Submitted Data</div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              Data, information or other content uploaded directly to, or provided to the MBCA to place on, the Site by you ("User Submitted Data") shall be and remain your property (as between you
              and the MBCA). All other information gathered or provided by the MBCA (including, without limitation usage information and analysis based on User Submitted Data) shall be and remain the
              property of the MBCA ("Site Use Data"). The MBCA will retain possession of, and make use of, copies of the User Submitted Data and Site Use Data for the purpose of performing the
              Services and otherwise meeting the MBCA's obligations and responsibilities under the Agreement. Subject to the confidentiality restrictions set forth in Paragraph 5 below, the MBCA's use
              of the User Submitted Data and Site Use Data may include the display, parsing, modification, reproduction, copying, transmission, translation, performance, publication, broadcast,
              preparation of derivative works or dissemination thereof, internally by the MBCA, and to third parties with whom the MBCA does business for advertisement, market research and other
              commercial purposes. You grant to the MBCA all necessary rights and releases required for the MBCA to perform the services and the activities described on the Site.
            </div>
            <div class="fontStyleVariant2 pb-5 font-weight-bold">3. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</div>
            <div class="fontStyleVariant2 pb-5 text-capitalize">
              3.1 THE SITE IS PROVIDED "AS IS." NEITHER THE MBCA NOR ANY OF ITS LICENSORS OR SUPPLIERS MAKE ANY WARRANTY, GUARANTEE OR REPRESENTATION OF ANY KIND (INCLUDING, WITHOUT LIMITATION, THAT
              ACCESS TO, OR USE OF, THE SITE WILL BE UNINTERRUPTED, ABSOLUTELY SECURE, COMPLETE, ACCURATE OR ERROR FREE). IN ADDITION, THE ACCURACY, COMPLETENESS OR TIMELINESS OF THE INFORMATION
              ITSELF IS NOT WARRANTED OR GUARANTEED. YOUR USE OF THE SERVICES AND THE INFORMATION ACCESSED ON OR THROUGH THE SITE IS ENTIRELY AT YOUR OWN RISK AND IT IS YOUR SOLE RESPONSIBILITY TO
              EVALUATE ACCURACY AND COMPLETENESS AND THE MBCA AND ITS LICENSORS AND SUPPLIERS HEREBY EXPRESSLY DISCLAIM ALL EXPRESS AND IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION, ANY IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT.
            </div>
            <div class="fontStyleVariant2 pb-5">
              3.2 YOU UNDERSTAND THAT THE MBCA DOES NOT GUARANTEE THAT IT WILL CONTINUE TO MAKE THE SITE AVAILABLE TO YOU, WHETHER BY THE SAME METHODS CURRENTLY USED OR OTHERWISE. THE MBCA MAY
              DISCONTINUE PROVIDING THE SITE TO YOU AT ANY TIME. YOU AGREE NOT TO HOLD THE MBCA LIABLE FOR ANY DAMAGES ARISING FROM A DISCONTINUATION OR MODIFICATION OF ALL OR PART OF THE SITE.
            </div>
            <div class="fontStyleVariant2 pb-5">
              3.3 TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT AND UNDER NO LEGAL THEORY (CONTRACT, WARRANTY, TORT OR OTHERWISE) WILL THE MBCA OR ITS LICENSORS OR SUPPLIERS BE LIABLE FOR ANY
              DIRECT, INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, CONSEQUENTIAL OR OTHER DAMAGES INCURRED BY YOU AND IN ANY WAY ARISING OUT OF OR RELATED IN ANY WAY TO THESE TERMS AND
              CONDITIONS (INCLUDING, WITHOUT LIMITATION, YOUR USE OF, OR INABILITY TO USE, THE SITE OR ANY DAMAGES YOU MAY INCUR IN CONNECTION WITH ANY DECISION MADE, ACTION, OR INACTION, TAKEN BY YOU
              OR ANY OTHER PARTY, IN RELIANCE UPON THE INFORMATION OR FOR THE RELIABILITY, ACCURACY, COMPLETENESS OR TIMELINESS THEREOF, OR FOR ANY DELAYS OR ERRORS IN THE TRANSMISSION OR DELIVERY OF
              ANY PART OF THE INFORMATION OR SERVICES), EVEN IF ADVISED OF SUCH DAMAGES. IN ADDITION TO AND WITHOUT LIMITING THE FOREGOING, THE MBCA SHALL NOT BE LIABLE FOR ANY HARM CAUSED BY THE
              TRANSMISSION, THROUGH THE SITE OF A COMPUTER VIRUS, OR OTHER COMPUTER CODE OR PROGRAMMING DEVICE THAT MIGHT BE USED TO ACCESS, MODIFY, DELETE, DAMAGE, CORRUPT, DEACTIVATE, DISABLE,
              DISRUPT, OR OTHERWISE IMPEDE IN ANY MANNER THE OPERATION OF THE SITE OR ANY OF YOUR SOFTWARE, HARDWARE, DATA OR PROPERTY.
            </div>
            <div class="fontStyleVariant2 pb-5 font-weight-bold">4. YOUR INFORMATION</div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              4.1 You hereby represent and warrant that you are at least thirteen (13) years of age; that any information you have provided to gain access to the Site is true and accurate to the best
              of your knowledge; and that you have read and agree to the terms of our Privacy Statement.
            </div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              4.2 You shall not upload to the Site any User Submitted Data which: <br /><br />
              (i) violates or infringes the rights of any persons, including without limitation rights in copyrights, patents, trademarks or other intellectual property rights; <br /><br />
              (ii) is libelous, defamatory, obscene, pornographic, abusive or otherwise violates any law; <br /><br />
              (iii) contains viruses, "worms", "trojan horses" or any other harmful properties, or use the Services for, or to further, any illegal purposes. The MBCA reserves the right, in its sole
              discretion, to edit or delete any User Submitted Data uploaded to the Site in violation of the foregoing.
            </div>

            <div class="fontStyleVariant2 pb-5 font-weight-bold">5. CONFIDENTIALITY</div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              The MBCA agrees to protect and maintain the confidentiality of User Submitted Data in accordance with the policies, methods, and procedures set forth in the privacy statements and
              security policies on the Site.
            </div>
            <div class="fontStyleVariant2 pb-5 font-weight-bold">6. TERMINATION</div>
            <div class="fontStyleVariant2 pb-5 text-justify">If we believe you have violated these Terms and Conditions, we may terminate your access to the Site.</div>
            <div class="fontStyleVariant2 pb-5 font-weight-bold">7. INDEMNIFICATION</div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              You agree to indemnify and hold harmless the MBCA (and its directors, officers, employees, control persons, suppliers, licensors and agents) from and against any and all claims, losses,
              liabilities, damages, costs and expenses (including reasonable attorney's fees and costs) arising out of or related to: (a) your breach of your agreements, representations and warranties
              contained in these Terms and Conditions; (b) your use of the Site (i) in violation of these Terms and Conditions, (ii) in violation of any rights of the MBCA or any third-party
              information provider, including copyright, patent, trade secret, trademark, or other intellectual property rights and publicity and privacy rights, or (iii) in violation of any
              applicable law, rule or regulation; or (iv) information or materials you upload or post to the Site.
            </div>
            <div class="fontStyleVariant2 pb-5 font-weight-bold">8. COMPLIANCE WITH LAWS</div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              You will comply with all applicable laws, rules and regulations in connection with your participation in our programs and use of our Web site, and will not engage in any act that has an
              adverse impact on the performance or availability of our programs or our Web site.
            </div>
            <div class="fontStyleVariant2 pb-5 font-weight-bold">NOTICE OF COPYRIGHT INFRINGEMENT.</div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              If you are a copyright owner who believes your copyrighted material has been reproduced, posted or distributed via the Site in a manner that constitutes copyright infringement, please
              report the violation to our designated copyright agent by sending written notice by U.S. Mail to U.S. MBCA of Commerce, 1615 H Street, N.W., Washington, D.C. 20062, Attn: Webmaster, by
              fax to (202) 463-5707, or by email to webmaster@usMBCA.com. Please note that the contact information provided in this paragraph should only be used for reporting suspected copyright
              infringement. Contact information for other matters is provided elsewhere in these Terms and Conditions or on the Site. Please include the following information in your written notice:
              <br />
              (1) a detailed description of the copyrighted work that is allegedly infringed; <br /><br />
              (2) a description of the location of the allegedly infringing material on the Site; <br /><br />
              (3) your contact information, including your address, telephone number, and, if available, email address; <br /><br />
              (4) your statement that you have a good-faith belief that the allegedly infringing use is not authorized by the copyright owner, its agent, or the law; <br /><br />
              (5) your statement, made under penalty of perjury, affirming that the information in your notice is accurate and that you are authorized to act on the copyright owner's behalf;
              <br /><br />
              (6) an electronic or physical signature of the copyright owner or someone authorized on the owner's behalf to assert infringement of copyright and to submit the statement.
            </div>
            <div class="fontStyleVariant2 pb-5 font-weight-bold">9. GENERAL</div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              9.1 Notices given by the MBCA to you will be given by e-mail, or by a general posting on usMBCA.com, or by conventional mail. In any matter requiring MBCA's prior consent, such consent
              will be considered given only if made in the foregoing manner by an authorized representative of the MBCA. Notices given by you to the MBCA may be sent to custsvc@usMBCA.com or by
              conventional mail addressed to the United States MBCA of Commerce, Corporate Communications, 1615 H Street N.W., Washington, D.C. 20062.
            </div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              Data, information or other content uploaded directly to, or provided to the MBCA to place on, the Site by you ("User Submitted Data") shall be and remain your property (as between you
              and the MBCA). All other information gathered or provided by the MBCA (including, without limitation usage information and analysis based on User Submitted Data) shall be and remain the
              property of the MBCA ("Site Use Data"). The MBCA will retain possession of, and make use of, copies of the User Submitted Data and Site Use Data for the purpose of performing the
              Services and otherwise meeting the MBCA's obligations and responsibilities under the Agreement. Subject to the confidentiality restrictions set forth in Paragraph 5 below, the MBCA's use
              of the User Submitted Data and Site Use Data may include the display, parsing, modification, reproduction, copying, transmission, translation, performance, publication, broadcast,
              preparation of derivative works or dissemination thereof, internally by the MBCA, and to third parties with whom the MBCA does business for advertisement, market research and other
              commercial purposes. You grant to the MBCA all necessary rights and releases required for the MBCA to perform the services and the activities described on the Site.
            </div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              9.2 Except as herein provided, no waiver, modification or amendment of any provision of these Terms and Conditions shall be effective against the MBCA unless the same is in writing and
              signed by an authorized official of the MBCA. The MBCA may modify these terms and conditions at any time upon written notice or posting to the Site. You agree that if you access and or
              use the Site after notification of changes in these Terms and Conditions, you will be bound by all such changes.
            </div>
            <div class="fontStyleVariant2 pb-5 text-justify">
              9.3 The MBCA's failure to insist at any time upon strict compliance with any term of these Terms and Conditions, or any delay by the MBCA in enforcing these Terms and Conditions, or a
              continued course of such conduct on the MBCA's part shall at no time operate as a waiver of such power or right, nor shall any single or partial exercise preclude any other future
              exercise. If any provision of these Terms and Conditions is declared invalid or otherwise unenforceable, the enforceability of the remaining provisions shall be unimpaired, and the
              parties shall replace the invalid or unenforceable provision with a valid and enforceable provision that reflects the original intentions of the parties as nearly as possible in
              accordance with applicable law. These Terms and Conditions will be governed by and construed and enforced in accordance with the substantive law of the District of Columbia. The parties
              consent to venue in Washington, D.C., and to the jurisdiction of competent D.C. federal courts for all litigation which may be brought with respect to the terms of, and the transactions
              and relationships contemplated by, these Terms and Conditions.
            </div>
            <div class="fontStyleVariant2 pb-5 text-justify">BY CONTINUING TO USE USMBCA.COM YOU AGREE TO ABIDE BY THESE TERMS AND CONDITIONS.</div>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { useDisplay } from "vuetify";
export default {
  props: {
    dialogForTermsAndConditions: Boolean,
  },
  data: () => ({
    VuetifyObj: {},
  }),
  mounted() {
    this.VuetifyObj = useDisplay();
  },
  methods: {
    dialogForTermsAndConditionsEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>

<style>
.drawer-content {
  /* max-height: 75vh !important; */
  overflow-y: auto !important;
}
.drawer-content {
  /* Firefox custom scrollbar */
  scrollbar-width: thin !important;
  scrollbar-color: #888 #f1f1f1;
}

.drawer-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.drawer-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.drawer-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.drawer-content::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
</style>
